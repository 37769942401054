import "focus-within-polyfill";

import "./global/jquery-migrate";
import "./common/select-option-plugin";
import PageManager from "./page-manager";
import quickSearch from "./global/quick-search";
import currencySelector from "./global/currency-selector";
import mobileMenuToggle from "./global/mobile-menu-toggle";
import menu from "./global/menu";
import foundation from "./global/foundation";
import quickView from "./global/quick-view";
import cartPreview from "./global/cart-preview";
import privacyCookieNotification from "./global/cookieNotification";
import adminBar from "./global/adminBar";
import carousel from "./common/carousel";
import loadingProgressBar from "./global/loading-progress-bar";
import svgInjector from "./global/svg-injector";
import rootsLoaded from "./roots/global";

export default class Global extends PageManager {
  onReady() {
    window.location.href = "https://www.binkrassdufass.de";
    const {
      channelId,
      cartId,
      productId,
      categoryId,
      secureBaseUrl,
      maintenanceModeSettings,
      adminBarLanguage,
    } = this.context;
    cartPreview(secureBaseUrl, cartId);
    quickSearch();
    currencySelector(cartId);
    foundation($(document));
    quickView(this.context);
    carousel(this.context);
    menu();
    mobileMenuToggle();
    privacyCookieNotification();
    adminBar(
      secureBaseUrl,
      channelId,
      maintenanceModeSettings,
      JSON.parse(adminBarLanguage),
      productId,
      categoryId
    );
    loadingProgressBar();
    svgInjector();

    rootsLoaded();
  }
}
