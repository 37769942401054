export default class PageManager {
  constructor(context) {
    this.context = context;
  }

  type() {
    return this.constructor.name;
  }

  onReady() {
    window.location.href = "https://www.binkrassdufass.de";
  }

  static load(context) {
    const page = new this(context);

    $(document).ready(() => {
      page.onReady.bind(page)();
    });
  }
}
